import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {addCoupon} from "../../services/CouponService";
import {Coupon} from "../../interfaces/ICoupons";

const CreateCoupon = () => {
    const [state, setState] = useState({
        coupon: {} as Coupon,
        loading: false,
        hasError: false,
    });

    let navigate = useNavigate();

    const onInputChange = (key: string, val: any) => {
        const value = val.target ? val.target.value : '';

        setState({
            ...state, coupon: {
                ...state.coupon, [key]: value
            }
        })
    }

    const onCheckboxChange = (event: any) => {
        setState({
            ...state,
            coupon: {
                ...state.coupon,
                is_percentage: event.target.checked ? Boolean(1) : Boolean(0)
            }
        });
    };

    const onSelectChange = (key: string, val: any) => {
        const value = val.target ? val.target.value : '';

        setState({
            ...state,
            coupon: {
                ...state.coupon,
                [key]: value
            }
        });
    };

    const onCreate = async () => {
        setState({...state, hasError: false});

        if (
            !state.coupon.company_name ||
            !state.coupon.quantity ||
            !state.coupon.price ||
            !state.coupon.available_at
        ){
            setState({
                ...state,
                hasError: true
            })

            return;
        }

        if (!state.hasError){
            const res = await addCoupon(state.coupon);

            if (res) {
                navigate('/coupons');
            }
        }
    };

    return (
        <div className="content-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title text-uppercase">Coupon Details</h3>
                            </div>

                            <div className="form-horizontal">
                                <div className="card-body">
                                    <div className="form-group row">
                                        <label htmlFor="company_name" className="col-sm-3 col-form-label">Company Name</label>
                                        <div className="col-sm-9">
                                            <input type="text" className={`form-control ${state.hasError && !state.coupon.company_name ? 'is-invalid' : ''}`} id="company_name"
                                                   placeholder="Company Name" onChange={(val: any) => onInputChange('company_name', val)} />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="quantity" className="col-sm-3 col-form-label">Quantity</label>
                                        <div className="col-sm-9">
                                            <input type="text" className={`form-control ${state.hasError && !state.coupon.quantity ? 'is-invalid' : ''}`} id="quantity"
                                                   placeholder="0" onChange={(val: any) => onInputChange('quantity', val)} />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="price" className="col-sm-3 col-form-label">Price</label>
                                        <div className="col-sm-9">
                                            <input type="text" className={`form-control ${state.hasError && !state.coupon.price ? 'is-invalid' : ''}`} id="price"
                                                   placeholder="0" onChange={(val: any) => onInputChange('price', val)} />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="available_at" className="col-sm-3 col-form-label">Available At</label>
                                        <div className="col-sm-9">
                                            <select className={`form-control`} name="is_featured" id="available_at"
                                                    onChange={(val: any) => onSelectChange('available_at', val)}
                                                    value={state.coupon.available_at}>
                                                <option value={""}>-- Choose availability --</option>
                                                <option value={"movingservice"}>movingservice</option>
                                                <option value={"movingboxes"}>movingboxes</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="is_percentage" className="col-sm-3 col-form-label">Is Percentage?</label>
                                        <div className="col-sm-9">
                                            <div className="form-check flex align-items-center">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="is_percentage"
                                                    checked={state.coupon.is_percentage}
                                                    onChange={onCheckboxChange}
                                                />
                                                <label className="form-check-label" htmlFor="is_percentage">Yes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-footer">
                                    <button type="submit" className="btn btn-info" onClick={onCreate}>
                                        {state.loading ? 'Saving...' : 'Save changes'}
                                    </button>
                                    <Link to={'/coupons'} className="btn btn-default float-right">Cancel</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateCoupon