import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {getCoupons, deleteCoupon} from "../../services/CouponService";
import {Coupon} from "../../interfaces/ICoupons";
import AlertConfirm from "../shared/AlertConfirm";

const CouponList = () => {
    const [state, setState] = useState({
        coupons: [] as Coupon[],
        loading: false,
        isDeleted: false,
        alertOpen: false,
        deleteCouponId: 0
    });

    const onDelete = async (id: number) => {
        setState({...state, alertOpen: true, deleteCouponId: id});
    };

    const onCloseAlert = async (confirm: any) => {
        if (confirm === 'confirm') {
            const isDeleted = await deleteCoupon(state.deleteCouponId);

            if (isDeleted) {
                setState({...state, isDeleted: true, alertOpen: false, deleteCouponId: 0});
            }
        } else if(confirm === 'cancel'){
            setState({...state, alertOpen: false, deleteCouponId: 0});
        }
    }

    useEffect(()=> {
        const __init = async () => {
            const coupons = await getCoupons();

            setState({...state, coupons})
        }

        __init()

    }, [state.isDeleted]);

    const renderCoupons = () => {
        return (
            state.coupons.map((coupon: any) => {

                const formattedCodes = coupon.code.split(',').join(', ');
                const formattedUsed = coupon.used.split(',').join(', ');

                return (
                    <tr>
                        <td>
                            {coupon.id}
                        </td>
                        <td>
                            {coupon.company_name}
                        </td>
                        <td>
                            {coupon.quantity}
                        </td>
                        <td>
                            {formattedCodes}
                        </td>
                        <td>
                            {coupon.price}
                        </td>
                        <td>
                            {coupon.available_at}
                        </td>
                        <td>
                            {formattedUsed}
                        </td>
                        <td>
                            {<span className={` fas fa-circle ${ coupon.status == 1 ? 'text-success' : 'text-danger'}`}></span>}
                        </td>
                        <td>
                            <div className="btn-group btn-group-sm">
                                <Link to={`/coupons/${coupon.id}`} className="btn btn-warning"><i className="fas fa-edit"></i></Link>
                                <a href="javascript:void(0)" className="btn btn-danger"
                                   onClick={() => onDelete(coupon.id)}><i className="fas fa-trash"></i></a>
                            </div>
                        </td>
                    </tr>
                )
            })
        );
    }

    return (
        <div className="content-header">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title text-uppercase">Coupons list</h3>
                                </div>

                                <div className="card-body table-responsive">
                                    <table className="table table-striped text-nowrap">
                                        <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Company Name</th>
                                            <th>Quantity</th>
                                            <th>Code</th>
                                            <th>Price</th>
                                            <th>Available At</th>
                                            <th>Used</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {renderCoupons()}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="card-footer">
                                    <Link to={'/coupons/create'} className="btn btn-info">
                                        Create new
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {state.alertOpen && <AlertConfirm
                title={'You are deleting the coupon'}
                message={'Are you sure you want to delete the coupon?'}
                isOpen={state.alertOpen}
                onClose={onCloseAlert}
            />}
        </div>
    );
}

export default CouponList