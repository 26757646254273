import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import { getUsers, deleteUser } from "../../services/UserService";

const UsersList = () => {
    const [state, setState] = useState({
        users: [],
        isDeleted: false,
    });

    const __init = async () => {
        const users = await getUsers();
        setState({...state, users})
    }

    const onDelete = async (id: number) => {
        const isDeleted = await deleteUser(id);

        if (isDeleted){
            setState({...state, isDeleted: true});
        }
    };

    useEffect(()=> {
        __init()
    },[state.isDeleted]);

    return <div className="content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title text-uppercase">Users list</h3>
                                </div>

                                <div className="card-body table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th>User name</th>
                                            <th>Email</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {state.users.map((user: any) => {
                                                return <tr>
                                                            <td>{user.name}</td>
                                                            <td>{user.email}</td>
                                                            <td>
                                                                <div className="btn-group btn-group-sm">
                                                                    <Link to={`/users/${user.id}`} className="btn btn-warning"><i className="fas fa-edit"></i></Link>
                                                                    <a href="javascript:void(0)" className="btn btn-danger" onClick={() => onDelete(user.id)}><i className="fas fa-trash"></i></a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                            })}
                                        
                                        </tbody>
                                    </table>
                                </div>

                                <div className="card-footer">
                                    <Link to={'/users/create'} className="btn btn-info">
                                        Create new
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
};

export default UsersList;
