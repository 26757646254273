import {Link, useParams, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getCouponDetails, saveCouponDetails} from "../../services/CouponService";
import {Coupon} from "../../interfaces/ICoupons";

const EditCoupon = () => {
    const [state, setState] = useState({
        hasError: false,
        loading: false,
        coupon: {} as Coupon,
    });

    let { id } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        const __init = async () => {
            const coupon = await getCouponDetails(id);

            setState({...state, coupon});
        }

        __init();

    }, []);

    const onInputChange = (key: string, val: any) => {
        const value = val.target ? val.target.value : '';

        setState({
            ...state, coupon: {
                ...state.coupon,
                [key]: value
            }
        })
    }

    const onSelectChange = (key: string, val: any) => {
        const value = val.target ? val.target.value : '';

        setState({
            ...state,
            coupon: {
                ...state.coupon,
                [key]: value
            }
        });
    };

    const onSave = async (id: any) => {

        const data = {
            price: state.coupon.price,
            available_at: state.coupon.available_at,
            status: state.coupon.status,
        }

        const res = await saveCouponDetails(id, data);

        if (res) {
            setState({...state, loading: false});
            navigate('/coupons');
        }
    };

    return (
        <div className="content-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title text-uppercase">Coupon Details</h3>
                            </div>

                            <div className="form-horizontal">
                                <div className="card-body">

                                    <div className="form-group row">
                                        <label htmlFor="price" className="col-sm-3 col-form-label">Price</label>
                                        <div className="col-sm-9">
                                            <input type="text" className={`form-control`} id="price"
                                                   placeholder="John"  onChange={(val: any) => onInputChange('price', val)} defaultValue={state.coupon.price}/>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="available_at" className="col-sm-3 col-form-label">Available At</label>
                                        <div className="col-sm-9">
                                            <select className={`form-control`} name="is_featured" id="available_at"
                                                    onChange={(val: any) => onSelectChange('available_at', val)}
                                                    value={state.coupon.available_at}>
                                                <option value={"movingservice"}>movingservice</option>
                                                <option value={"movingboxes"}>movingboxes</option>
                                            </select>
                                        </div>
                                    </div>

                                    {!state.coupon.status ? "" : <div className="form-group row">
                                        <label htmlFor="status" className="col-sm-3 col-form-label">Status</label>
                                        <div className="col-sm-9">
                                            <select
                                                name="status"
                                                id="status"
                                                className={`form-control`}
                                                onChange={(val: any) => onSelectChange('status', val)}
                                                value={state.coupon.status}
                                            >
                                                <option value={true.toString()}>Active</option>
                                                <option value={false.toString()}>Inactive</option>
                                            </select>
                                        </div>
                                    </div>}

                                </div>

                                <div className="card-footer">
                                    <button type="submit" className="btn btn-info" onClick={() => onSave(id)}>
                                        {state.loading ? 'Saving...' : 'Save changes'}
                                    </button>
                                    <Link to={'/coupons'} className="btn btn-default float-right">Cancel</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default EditCoupon;