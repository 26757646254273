import {Routes, Route, useNavigate, Navigate} from "react-router-dom";
import DashboardLayout from "../views/layout/DashboardLayout";
import Login from "../views/Login";
import React from "react";
import {PrivateRoute} from "./PrivateRoute";
import {isAuthenticated} from "../services/AuthenticationService";
import EditCompany from "../components/companies/EditCompany";
import ResetPassword from "../views/ResetPassword";
import ProductList from "../components/products/ProductList";
import CreateProduct from "../components/products/CreateProduct";
import EditProduct from "../components/products/EditProduct";
import CompaniesList from "../components/companies/CompaniesList";
import ForgotPassword from "../views/ForgotPassword";
import ResetPasswordLinkExpired from "../views/ResetPasswordLinkExpired";
import CreateCompany from "../components/companies/CreateCompany";
import UsersList from "../components/users/UsersList";
import EditUser from "../components/users/EditUser";
import CreateUser from "../components/users/CreateUser";
import CouponList from "../components/coupons/CouponList";
import CreateCoupon from "../components/coupons/CreateCoupon";
import EditCoupon from "../components/coupons/EditCoupon";

const RouteList = () => {
    return (
        <Routes>
            <Route path="/"  element={
                <PrivateRoute isAuthenticated={isAuthenticated()}>
                    <DashboardLayout />
                </PrivateRoute>
            }>

                {/* Companies */}
                <Route path="/companies"  element={<CompaniesList />}/>
                <Route path="/companies/:id"  element={<EditCompany />}/>
                <Route path="/companies/create"  element={<CreateCompany />}/>

                {/* Products */}
                <Route path="/products"  element={<ProductList />}/>
                <Route path="/product/:id"  element={<EditProduct />}/>
                <Route path="/product/create"  element={<CreateProduct />}/>

                {/* Users */}
                <Route path="/users"  element={<UsersList />}/>
                <Route path="/users/:id"  element={<EditUser />}/>
                <Route path="/users/create"  element={<CreateUser />}/>

                {/* Coupons */}
                <Route path="/coupons"  element={<CouponList />}/>
                <Route path="/coupons/create"  element={<CreateCoupon />}/>
                <Route path="/coupons/:id"  element={<EditCoupon />}/>

            </Route>

            <Route path="/login"  element={
                isAuthenticated() ? <Navigate to="/companies" replace={true} /> : <Login />
            }/>
            {/* Forgot Password */}
            <Route path="/forgot-password"  element={<ForgotPassword />}/>
            {/* Forgot Password */}
            <Route path="/reset-password/:token"  element={<ResetPassword />}/>
            {/* Reset Password Link Expired*/}
            <Route path="/reset-password/expired"  element={<ResetPasswordLinkExpired />}/>
        </Routes>
    )
}

export default RouteList;