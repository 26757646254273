import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import { createUser } from "../../services/UserService";

const CreateUser = () => {
    const [state, setState] = useState({
        name: '',
        email: '',
        password: "",
        loading: false,
        hasError: false,
    });

    let navigate = useNavigate();

    const onInputChange = (key: string, val: any) => {
        const value = val.target ? val.target.value : '';

        setState({
            ...state, ...state,
            [key]: value
        })
    }

    const onCreate = async () => {
        setState({...state, hasError: false});

        if (
            !state.name ||
            !state.email ||
            !state.password
        ){
            setState({
                ...state,
                hasError: true
            })

            return;
        }

        if (!state.hasError){
            const res = await createUser(state);

            if (res) {
                navigate('/users');
            }
        }
    };

    return <div className="content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title text-uppercase">User Details</h3>
                                </div>

                                <div className="form-horizontal">
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <label htmlFor="first_name" className="col-sm-3 col-form-label">User Name</label>
                                            <div className="col-sm-9">
                                                <input type="text" className={`form-control ${state.hasError && !state.name ? 'is-invalid' : ''}`} id="name"
                                                    placeholder="Name" onChange={(val: any) => onInputChange('name', val)}/>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label htmlFor="last_name" className="col-sm-3 col-form-label">Email</label>
                                            <div className="col-sm-9">
                                                <input type="text" className={`form-control ${state.hasError && !state.email ? 'is-invalid' : ''}`} id="email"
                                                    placeholder="Email" onChange={(val: any) => onInputChange('email', val)}/>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label htmlFor="last_name" className="col-sm-3 col-form-label">Password</label>
                                            <div className="col-sm-9">
                                                <input type="password" className={`form-control ${state.hasError && !state.password ? 'is-invalid' : ''}`} id="password"
                                                    placeholder="Password" onChange={(val: any) => onInputChange('password', val)}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-info" onClick={onCreate}>
                                            {state.loading ? 'Saving...' : 'Save changes'}
                                        </button>
                                        <Link to={'/users'} className="btn btn-default float-right">Cancel</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
};

export default CreateUser;
